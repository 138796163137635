<template>
  <div>
    <a-card>
      <!-- 支付配置管理 卡片主体 -->
      <div class="table-operator">
        <a-form layout="inline">
          <a-row :gutter="0">
            <a-col :md="8" :sm="24">
              <a-form-item label="门店编号:">
                <a-input
                  v-model="queryParam.facilityCode"
                  allowClear
                  placeholder="请输入门店编号"
                />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="门店名称:">
                <a-input
                  v-model="queryParam.facilityName"
                  allowClear
                  placeholder="请输入门店名称"
                />
              </a-form-item>
            </a-col>
            <a-col
              :md="(!advanced && 3) || 24"
              :sm="24"
              style="text-align: right"
            >
              <span
                class="table-page-search-submitButtons"
                :style="
                  (advanced && { float: 'right', overflow: 'hidden' }) || {}
                "
              >
                <a-button type="primary" @click="dataSearch">查询</a-button>
                <a-button
                  style="margin-left: 8px"
                  @click="() => ((queryParam = {}), this.init())"
                  >重置</a-button
                >
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <a-table
        rowKey="facilityId"
        :pagination="pagination"
        :columns="columns"
        :dataSource="data"
        @change="changePageSize"
        style="margin-bottom: 24px"
      >
        <span
          slot="name"
          slot-scope="text, record"
          :class="record.name != '' ? '' : 'red'"
        >
          {{ record.name != "" ? record.name : "尚未配置" }}
        </span>
        <span slot="action" slot-scope="text, record" class="blueColor">
          <a @click="financePayModal(record)">{{
            record.name != "" ? "修改" : "新增"
          }}</a>
        </span>
      </a-table>
    </a-card>
    <!-- 编辑卡片 -->
    <a-modal
      title="支付主体配置"
      :width="550"
      :visible="viewFinPayment"
      :confirmLoading="confirmLoading"
      destroyOnClose
      @ok="handleFinPayment"
      @cancel="handleCancel"
    >
      <a-spin :spinning="confirmLoading">
        <a-form layout="inline" :form="form" style="line-height: 0">
          <a-row :gutter="0">
            <a-col>
              <a-form-item label="门店编号：">
                <span>{{ thisPayDetail.facilityCode }}</span>
              </a-form-item>
              <a-form-item label="门店名称：">
                <span>{{ thisPayDetail.facilityName }}</span>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="0">
            <a-col>
              <a-form-item label="签约公司主体:">
                <span>{{ thisPayDetail.companyEntityName }}</span>
                <!-- <a-input
                  style="min-width: 300px;"
                  v-model="thisPayDetail.companyEntityName"
                  placeholder="请输入签约公司主体"
                /> -->
              </a-form-item>
            </a-col>
          </a-row>
          <div v-for="(item, index) in company" v-bind:key="index">
            <h3 style="margin: 1.2em 0">{{ item.payType }}</h3>
            <a-row :gutter="0">
              <a-col>
                <a-form-item label="银联子客户号:">
                  <a-input
                    style="width: 300px;"
                    v-model="item.subBankAccount"
                    placeholder="请输入"
                  />
                </a-form-item>
              </a-col>
              <a-col>
                <a-form-item label="银联终端号： ">
                  <a-input
                    style="width: 300px;margin-left: 1em;"
                    v-model="item.terminalCode"
                    placeholder="请输入"
                  />
                </a-form-item>
              </a-col>
            </a-row>
          </div>
        </a-form>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
  

import Bus from "@/bus";
import { defaultPagination } from "@/utils/pagination";
export default {
  name: "financePayment",
  data() {
    return {
      // 布局
      advanced: false,
      // 签约公司主体
      // 弹窗主体信息
      company: {},
      // 默认data
      data: [],
      confirmLoading: false,
      form: this.$form.createForm(this),
      // 搜索功能
      queryParam: {
        facilityName: "",
        facilityCode: ""
      },
      pagination: defaultPagination(() => {}),
      // 表格头部配置
      columns: [
        {
          title: "门店名称",
          dataIndex: "facilityName",
          key: "id",
          fixed: "left"
        },
        {
          title: "支付收款主体",
          dataIndex: "name",
          scopedSlots: { customRender: "name" }
        },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
          key: "action",
          width: 140,
          fixed: "right"
        }
      ],
      // 弹窗
      viewFinPayment: false,
      finPayConfirm: false,

      // 承接
      thisDetail: "",
      thisPayDetail: "",
      // 接口来的门店数据
      storeList: []
    };
  },
  created() {
    this.init();
    Bus.$on("getTarget", target => {
      this.init();
    });
  },
  methods: {
    // 换页
    changePageSize(val) {
      this.init(val.current, val.pageSize);
    },
    // 弹窗保存 编辑函数
    // 这个函数需要改
    handleFinPayment() {
      let obj = {};

      if (!this.thisPayDetail.companyEntityName) {
        this.$message.error("签约公司主体有误！");
        return;
      }
      // 验证去除
      // this.$message.error(vMsg);

      // let vMsg = this.validateFinContract(this.company);

      // if(vMsg != 'success'){
      //   this.$message.error(vMsg);
      //   return
      // }

      this.confirmLoading = true;
      obj = Object.assign({}, this.thisPayDetail);
      obj.facilityId = obj.facilityId ? obj.facilityId : "";
      obj.payEntityConfigDTOs = this.company;

      axios({
        url:   this.$baseUrl + "payEntity/payEntityCostConfig",
        method: "POST",
        data: obj
      }).then(res => {
        this.confirmLoading = false;
        this.viewFinPayment = false;
        if (res.data.success) {
          this.$message.success("保存成功！");
          this.viewFinContract = false;
          this.init();
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
      });
    },
    // 验证完整性函数
    // validateFinContract(data){
    //   var msg = 'success';
    //   for (let i=0; i < data.length; i++) {
    //     // 这里写验证
    //     if (!data[i].subBankAccount || isNaN(data[i].subBankAccount*1)){
    //       msg = data[i].payType + '银联子客户号填写有误！';
    //       break;
    //     }else if (!data[i].terminalCode || isNaN(data[i].terminalCode*1)){
    //       msg = data[i].payType + '银联终端号填写有误！';
    //       break;
    //     }
    //     delete data[i].gmtCreated
    //     delete data[i].gmtModified
    //     delete data[i].creator
    //     delete data[i].modifier
    //   }
    //   return msg
    // },
    // 弹窗取消
    handleCancel() {
      this.viewFinPayment = false;
    },
    // 门店筛选接口
    init(current, pageSize) {
      axios({
        url:   this.$baseUrl + "payEntity/list",
        withCredentials: true,
        method: "GET",
        params: {
          rows: pageSize || 20,
          page: current || 1,
          ...this.queryParam
        }
      }).then(res => {
        this.data = res.data.rows;
        if (res.data.rows) {
          this.data = res.data.rows;
          const pagination = { ...this.pagination };
          pagination.total = res.data.count;
          this.pagination = pagination;
        }
      });
    },
    select() {
      this.init();
    },
    //编辑按钮
    financePayModal(val) {
      this.thisDetail = Object.assign({}, val);
      this.confirmLoading = true;

      // 判断是否请求
      axios({
        url:   this.$baseUrl + "payEntity/payEntityCostConfigs",
        withCredentials: true,
        method: "GET",
        params: {
          payEntityId: this.thisDetail.id,
          facilityId: this.thisDetail.facilityId
        }
      }).then(res => {
        this.confirmLoading = false;
        if (!res.data.success) {
          this.viewFinPayment = false;
          this.$message.error(res.data.returnMsg);
          return;
        }
        this.thisPayDetail = res.data.obj;
        this.company = res.data.obj.payEntityConfigDTOs;
        this.viewFinPayment = true;
      });
    },

    // 查询
    dataSearch() {
      this.init();
    }
  }
};
</script>

<style scoped>
.table-operator {
  margin-bottom: 18px;
}
</style>
